<template>
  <div>
    <v-card
      style="margin-top:20px; margin-right:10px; margin-left:10px; margin-bottom:30px; padding:10px 30px;"
    >
      <v-row no-gutters>
        <v-col cols="12" style="margin-top:10px; margin-bottom:20px;">
          <div
            style="border-radius: 10px 10px 0 0;"
            :class="
              `d-flex justify-start ${
                wWidth < 769 ? 'flex-column' : 'flex-row'
              }`
            "
          >
            <v-toolbar-title>List Project</v-toolbar-title>
            <v-divider class="mx-6" inset vertical></v-divider>
            <div
              style="display:flex; justify-content:center; align-items:center;"
            >
              <router-link to="/e-catalogue/project/submission">
                <v-btn
                  outlined
                  small
                  elevation="1"
                  color="indigo"
                  class="indigo--text"
                  style="font-size:12px;margin-right:40px;"
                >
                  Buat Baru
                </v-btn>
              </router-link>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-start align-center">
            <div
              style="margin-top:5px; margin-bottom:15px; margin-right:15px; width:120px;"
            >
              <v-select
                :disabled="selectedData.length === 0"
                label="Action"
                style="
                      position: relative;
                      top: 15px;
                      margin: 0 15px 0 5px;
                      font-size: 12px;
                    "
                v-model="actionValue"
                :items="itemsAction"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                @change="actionSelect"
              ></v-select>
            </div>
            <div style="margin-top:5px; margin-bottom:15px; margin-right:10px;">
              <v-autocomplete
                v-if="
                  getUserProfile.level.find(({ id }) => id === '41') !==
                    undefined
                "
                v-model="paramAPI.company_id"
                :items="dropdownCompany"
                @change="changeCompany"
                label="- Perusahaan -"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0;height:42px;"
                clearable
              ></v-autocomplete>
              <v-autocomplete
                v-else
                v-model="paramAPI.company_id"
                :items="dropdownCompany"
                @change="changeCompany"
                label="- Perusahaan -"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0;height:42px;"
                :disabled="isDisableCompanyDropdown"
                clearable
              ></v-autocomplete>
            </div>
            <!-- <div
              style="height:42px;margin-top:5px; margin-bottom:15px; margin-right:10px;"
            >
              <v-text-field
                class="ml-1 mr-2"
                dense
                label="Tanggal"
                type="date"
                name="startdate"
                style="height:30px;"
                step="1"
                outlined
                clearable
              />
            </div> -->
            <div
              style="margin-left:auto; height:42px; width:320px; margin-top:5px; margin-bottom:15px; margin-right:10px;"
            >
              <v-text-field
                v-model="paramAPI.keyword"
                @keyup.enter="searchEnter"
                label="Cari disini"
                type="cari"
                outlined
                dense
                append-icon="mdi-magnify"
              ></v-text-field>
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            mobile-breakpoint="0"
            class="elevation-1"
            style="cursor:pointer;"
            :headers="headers"
            :items="result"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            :page="paramAPI.page"
            :items-per-page="paramAPI.itemsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            item-key="id"
            show-select
          >
            <template v-slot:[`item.status`]="{ item }">
              <td>
                {{ status(item.status) }}
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <div style="position:fixed; bottom:10px; right:0;">
      <Scanner />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
// import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  components: {
    Scanner: () => import('../../home/components/Scanner.vue')
  },
  data: () => ({
    wWidth: window.innerWidth,
    proc: buildType.apiURL('proc'),
    eSanqua: buildType.apiURL('esanqua'),
    hrsApi: buildType.apiURL('hrs'),
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      length: 0,
      sortBy: 'name',
      sortType: 'desc',
      itemsPerPage: 10,
      company_id: null,
      date: ''
    },
    totalData: 0,
    headers: [
      // {
      //   text: 'No. Project',
      //   value: 'project_no',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Kode Project',
        value: 'odoo_project_code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama Project',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Unit/Plant',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Penanggung Jawab',
        value: 'employee_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Buat',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    selected: null,
    loading: true,
    dropdownCompany: [],
    isDisableCompanyDropdown: false,

    itemsAction: [],
    actionValue: 0,
    selectedData: []
  }),
  watch: {
    selectedData() {
      if (this.selectedData.length !== 0) {
        this.itemsAction = [
          { id: 0, name: '' },
          { id: 1, name: 'Delete' }
        ]
      } else {
        this.itemsAction = [{ id: 0, name: '' }]
      }
    },
    'paramAPI.sortBy'() {
      this.getDataFromApi()
    },
    'paramAPI.sortType'() {
      this.getDataFromApi()
    },
    'paramAPI.status'() {
      this.getDataFromApi()
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    // get latest parameter
    // if (this.getFpbParam != null) {
    //   this.paramAPI = this.getFpbParam
    // }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    actionSelect(event) {
      switch (event) {
        case 1:
          this.deleteProject()
          break
      }
    },
    rowClick(pValue, pSlot) {
      // const arrItem = {
      //   list: this.result
      // }
      // this.$store.commit('setProjectList', arrItem)

      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/project/detail/${pValue.id}?idx=${pSlot.index}`
        )
      }, 200)
    },
    async changeCompany(p) {
      this.paramAPI.company_id = p

      this.clearPaging()
      await this.getDataFromApi()
    },
    startDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    endDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    async initDropdown() {
      await this.getDropdownCompany()
      // await this.getDropdownDepartment()
    },
    async getDropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) =>
          id === '3' || id === '14' || id === '23' || id === '27' || id === '41'
      )
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdownCompany = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          this.dropdownCompany = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then(data => {
          if (data.status_code === '00') {
            this.totalData = Number(data.total_record)
            return (this.result = data.data)
          } else {
            this.totalData = 0
            return (this.result = [])
          }
        })
        .catch(err => {
          console.log(err)
          return (this.result = [])
        })
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    initDataTable() {
      let url = `${this.proc}project/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.sortBy}&order_type=${
        this.paramAPI.sortType
      }&status=${this.paramAPI.status}`
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      this.url = url
      this.$store.commit('setProjectItems', this.paramAPI)
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async searchEnter() {
      await this.getDataFromApi()
    },
    clearPaging() {
      this.paramAPI.page = 1
      this.paramAPI.offset = 0
      this.paramAPI.limit = 10
      this.paramAPI.itemsPerPage = 10
    },
    status(val) {
      switch (val) {
        case 0:
          return 'Draft'
        case 1:
          return 'Submited'
      }
    },
    colorStatus(val) {
      switch (val.id) {
        case -1:
          return 'red'
        case 0:
          return '#1976d2'
        case 1:
          return 'orange'
      }
    },
    deleteProject() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data akan dihapus secara permanent, Anda yakin ?`,
        true
      ).then(res => {
        if (res.isConfirmed) {
          this.startDelete()
        } else {
          this.actionValue = 0
        }
      })
    },
    async startDelete() {
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .delete(`${this.proc}project/delete/${this.selectedData[i].id}`)
            .then(res => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch(err => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.actionValue = 0
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil terhapus <br/> ${arrFail.length} data gagal terhapus pada index [${arrFail}]`,
        false
      )
      return this.getDataFromApi()
    },

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, input) {
      const settings = {
        position: 'center',
        icon: pModalType,
        html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
        showConfirmButton: true,
        showCancelButton: pBtnCancel,
        cancelButtonText: 'No'
      }

      if (input !== undefined) {
        if (input) {
          Object.assign(settings, {
            inputPlaceholder: 'Tuliskan keterangan',
            input: 'text'
            // inputAttributes: {
            //   autocapitalize: 'false'
            // }
          })
        }
      }

      return new Promise(resolve => {
        this.$swal.fire(settings).then(r => {
          resolve(r)
        })
      })
    }
    // async exportData() {
    //   const url = this.url + '&is_export=true'
    //   await axios
    //     .get(url)
    //     .then(res => {
    //       console.log(res)
    //       let selectedData = []
    //       const response = res.data.data
    //       if (response !== undefined) {
    //         for (let i = 0; i < response.length; i++) {
    //           selectedData.push({
    //             request_no: response[i].request_no,
    //             employee: response[i].employee.name,
    //             department: response[i].department.name,
    //             company: response[i].company.name,
    //             submit_date: response[i].requested_at,
    //             status: response[i].status.name,
    //             created_at: response[i].created_at,
    //             item: response[i].item
    //           })
    //         }
    //         this.exportNow(selectedData)
    //       } else {
    //         selectedData = []
    //       }
    //       return null
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       return null
    //     })
    // },
    // exportNow(selectedData) {
    //   const arrData = []
    //   for (let i = 0; i < selectedData.length; i++) {
    //     const param = {
    //       request_no: selectedData[i].request_no,
    //       employee: selectedData[i].employee,
    //       department: selectedData[i].department,
    //       company: selectedData[i].company,
    //       submit_date: selectedData[i].submit_date,
    //       created_at: selectedData[i].created_at,
    //       status_fpb: selectedData[i].status,
    //       product_code: selectedData[i].item.product_code,
    //       product_name: selectedData[i].item.product_name,
    //       qty: selectedData[i].item.qty,
    //       uom: selectedData[i].item.uom_name,
    //       quotation_per_unit: selectedData[i].item.quotation_price_per_unit,
    //       budget_per_unit: selectedData[i].item.budget_price_per_unit,
    //       budget_total: selectedData[i].item.budget_price_total,
    //       last_price: selectedData[i].item.last_price,
    //       date_use: selectedData[i].item.estimate_date_use,
    //       pr_no: selectedData[i].item.pr_no,
    //       status_item: selectedData[i].item.status,
    //       description: selectedData[i].item.description
    //     }
    //     arrData.push(param)
    //   }
    //   this.downloadExcell(arrData)
    // },
    // downloadExcell(arrData) {
    //   const data = XLSX.utils.json_to_sheet(arrData)
    //   const wb = XLSX.utils.book_new()
    //   XLSX.utils.book_append_sheet(wb, data, 'data')
    //   const date = new Date()
    //   XLSX.writeFile(wb, `FPB_${date.toLocaleDateString('id')}.xlsx`)
    // },
    // price(pItem) {
    //   if (pItem !== undefined) {
    //     if (pItem !== null) {
    //       return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
    //     }
    //   }
    //   return ''
    // }
  }
}
</script>

<style lang="scss" scoped></style>
